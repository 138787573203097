import { Component, HostBinding } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngu-tile',
  template: `<div class="tile">
  <ng-content></ng-content>
</div>
`,
  styles: [`:host{padding:10px;box-sizing:border-box}.tile{box-shadow:0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)}`]
})
export class NguTileComponent {
  @HostBinding('class.item') classes = true;
}
