import { Component, HostBinding } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngu-item',
  template: `<ng-content></ng-content>
`,
  styles: [``]
})
export class NguItemComponent {
  @HostBinding('class.item') classes = true;
}
